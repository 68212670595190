/* eslint-disable */
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { orderBy, uniqBy } from "lodash";
import remoteConfig from "../firebase";
export function changeZipFormate(zipNumber) {
  if (zipNumber) {
    let r = zipNumber.replace(/\D/g, '')
    r = r.replace(/^0/, '')
    return r.replace(/^(\d{5})(\d{4}).*/, '$1$2')
  }
  return ''
}

// Convert Number type value to money
Number.prototype.toMoney1 = function (decimals, decimal_sep, thousands_sep) {
  let n = this
  const c = isNaN(decimals) ? 2 : Math.abs(decimals)
  const d = decimal_sep || '.'
  // if no decimal separator is passed we use the dot as default decimal separator (we MUST use a decimal separator)
  /*
    according to [https://stackoverflow.com/questions/411352/how-best-to-determine-if-an-argument-is-not-sent-to-the-javascript-function]
    the fastest way to check for not defined parameter is to use typeof value === 'undefined'
    rather than doing value === undefined.
    */

  const t = typeof thousands_sep === 'undefined' ? ',' : thousands_sep
  // if you don't want to use a thousands separator you can pass empty string as thousands_sep value

  const sign = n < 0 ? '-' : ''

  // extracting the absolute value of the integer part of the number and converting to string

  const i = `${parseInt((n = Math.abs(n).toFixed(c)))}`

  var j = (j = i.length) > 3 ? j % 3 : 0
  return (
    sign +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : '')
  )
}

export function toMoney(price, addComma = true) {
  if (typeof price === 'string') {
    price = parseFloat(price)
  }
  price = price || 0
  if (addComma) {
    return price.toMoney1(2, '.', ',')
  }
  return price.toMoney1(2, '.', '')
}

// Convert String type value to money
String.prototype.toMoney = function () {
  const price = parseFloat(this) || 0
  return `${price.toMoney1()}`
}

export function changePriceFormat(price, fixed = 2) {
  if (typeof price === 'string') {
    price = parseFloat(price)
  }
  price = price || 0
  return Number(price)
}

export const checkEmptyCardForm = (keyObject) => {
  const {
    cardNumber,
    cardExpiry,
    cardCvc,
    postalCode,
    cardHolderNameEmpty,
  } = keyObject
  if (
    !cardNumber &&
    !cardExpiry &&
    !cardCvc &&
    !postalCode &&
    !cardHolderNameEmpty
  ) {
    return false
  }
  return true
}

export const getAmountToDisplay = (currency = { symbol: '$' }, amount) => {
  const symbol = currency ? currency.symbol : ''
  amount =
    amount < 0 ? `(${symbol}${toMoney(amount * -1)})` : `${symbol}${toMoney(amount)}`
  return `${amount}`
}

export const getCurrentYear = (date = new Date()) => {
  var currentYear = date.getFullYear()
  return currentYear
}

export const showRepeatOn = (data) => {
  switch (data.unit) {
    case 'daily':
      return 'Every day of the week'
    case 'weekly':
      return `Every ${data.dayOfWeek}`
    case 'monthly':
      return `On the ${
        data.dayofMonth < 2 || data.dayofMonth === '1' ? 'first' : data.dayofMonth
      } day of each month`
    case 'yearly':
      return `Every ${data.monthOfYear} on the ${
        data.dayofMonth < 2 || data.dayofMonth === '1' ? 'first' : data.dayofMonth
      } day of the month`
    case 'custom':
      return `Every ${data.interval} ${data.subUnit} in ${data.monthOfYear} on the ${
        data.dayofMonth < 2 || data.dayofMonth === '1' ? 'first' : data.dayofMonth
      } day of the month`
    default:
      return null
  }
}

export const isEmpty = (obj) => {
  for (var key in obj) if (obj.hasOwnProperty(key)) return false
  return true
}

export const _isValidEmail = (email) => {
  const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regEx.test(email)
}

export const getCountryById = (id, countries) => {
  var result = countries.find((obj) => {
    return obj.id ===id
  })
  return result ? { id: result.id, name: result.name, sortname: result?.sortname } : { id: 101, name: 'India' }
}

export const getRegionById = (id, states) => {
  var result = states.find((obj) => {
    return obj.id ===id
  })
  return result ? { id: result.id, name: result.name,  } : { id: -1, name: '' }
}

export const setCountries = (countries) => {
  return countries && countries.length ? (
    countries.map((item, i) => {
      return (
        <option key={i} value={item.id}>
          {' '}
          {item.name}
        </option>
      )
    })
  ) : (
    <option key={-1} value={0}>
      {' '}
      {'None'}
    </option>
  )
}

export const setCountryStates = (countryStates) => {
  return countryStates && countryStates.length > 0 ? (
    countryStates.map((item, i) => {
      return (
        <option key={i} value={item.id}>
          {item.name}
        </option>
      )
    })
  ) : (
    <option key={-1} value={0} disabled>
      {'None'}
    </option>
  )
}

export const setCurrencyList = list => {
  let countries = list;
  let currencies = countries.map(country => {
    return country.currencies[0];
  });
  currencies = orderBy(uniqBy(currencies, "code"), "code", "asc");
  return currencies;
};

export const processingFeeAmount = (amount) => {
  return parseFloat(amount).toFixed(2);
}

export function help() {
  window.$crisp = [];
  window.CRISP_WEBSITE_ID = process.env.REACT_APP_CRISP_WEBSITE_ID;

  (function () {
    const d = document;
    const s = d.createElement("script");

    s.src = "https://client.crisp.chat/l.js";
    s.async = 1;
    d.getElementsByTagName("head")[0].appendChild(s);
  })();
  window.$crisp.push(['do', 'chat:open'])
}

export const remoteConfigFunction = async(ele) => {
  window.remoteConfigData={...window.remoteConfigData}
  const isFetched = await fetchAndActivate(remoteConfig);
    const flag = getValue(remoteConfig, `${ele}`);
    if(isFetched){
      window.remoteConfigData[`${ele}`] = flag._value === 'false' ? false : true 
    } else {
      window.remoteConfigData[`${ele}`] = flag._value === 'false' ? false : true
    }
    return flag._value
}

export const calculatePayLaterFees = async (amount, shouldAskProcessingFee) => {
  const feeStructure = {
    'international_fee': {
      dynamic: 0.099,
      fixed: 0
    },
    'fee': {
      dynamic: 0,
      fixed: 0
    }
  }
  if (shouldAskProcessingFee) {
    let feeType = 'international_fee';
    let total = parseFloat(amount).toFixed(2);
    const dynamicFee = feeStructure[feeType].dynamic;
    const fee = parseFloat(parseFloat((total / (1 - dynamicFee)) + feeStructure[feeType].fixed) - parseFloat(total)).toFixed(2);
    let totalWithFee = parseFloat(parseFloat(total) + parseFloat(fee)).toFixed(2);
    return {
      total: totalWithFee,
      fee
    }
  } else {
    let feeType = 'fee';

    let total = parseFloat(amount).toFixed(2);

    const dynamicFee = feeStructure[feeType].dynamic;
    const fee = (total * dynamicFee + feeStructure[feeType].fixed).toFixed(2);

    return {
      total,
      fee,
    }
  }
}

export const getProviderFeeStructure = (type, feeStructure) => {
  switch (type) {
    case 'card':
      return feeStructure?.find((el) => el.type === 'card')
    case 'payByBank':
    case 'bank':
      return feeStructure?.find((el) => el.type === 'bank')
    case 'bnpl':
      return feeStructure?.find((el) => el.type === 'bnpl')
    default:
      return feeStructure?.find((el) => el.type === 'card')
  }
}

export const getProviderCalculatedFee = (amount, fee) => {
  return parseFloat(
    parseFloat(
      (amount + fee?.international_fee?.fixed)
      / (1 - fee?.international_fee?.dynamic)
    ) - parseFloat(amount)
  ).toFixed(2)
}